export const LogoFullStacked = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#d37b1c'
  color3 = color3 ? color3 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 903.6461 208.9077"
      >
        <title>RE Logo Full Stacked</title>
        <circle fill={color1} cx="104.4539" cy="104.4539" r="104.4539"/>
        <path fill={color3} d="M86.5437,141.3294,71.4773,119.7307a27.7692,27.7692,0,0,1-2.8447.1055H51.9861v21.4932H38.2893V67.5784H68.6326A40.83,40.83,0,0,1,85.3318,70.74,24.2829,24.2829,0,0,1,96.2366,79.8,25.3544,25.3544,0,0,1,100.03,93.8128a24.9931,24.9931,0,0,1-4.0566,14.3281,24.1274,24.1274,0,0,1-11.6421,8.956l16.9629,24.2325ZM81.5916,82.96Q76.9546,79.1678,68,79.1672H51.9861v29.3956H68q8.9538,0,13.5913-3.8458,4.6347-3.8451,4.6357-10.9042T81.5916,82.96Z"/>
        <path fill={color3} d="M170.6184,129.845v11.4844h-55.313V67.5784h53.8384V79.0628H129.0022V98.2376H164.613V109.511H129.0022v20.334Z"/>
        <path fill={color1} d="M330.4452,82.3937,314.5658,59.63a29.25,29.25,0,0,1-2.9982.1112H294.023V82.3937H279.5872V4.6635h31.98a43.0329,43.0329,0,0,1,17.6,3.3317,25.5928,25.5928,0,0,1,11.4931,9.5494,26.7232,26.7232,0,0,1,3.9976,14.7688,26.341,26.341,0,0,1-4.2755,15.1012,25.4286,25.4286,0,0,1-12.27,9.4392l17.8781,25.54Zm-5.2194-61.5184q-4.8872-3.997-14.3246-3.9976H294.023V47.8592h16.8782q9.4369,0,14.3246-4.0532,4.8849-4.0527,4.8859-11.4926T325.2258,20.8753Z"/>
        <path fill={color1} d="M414.9478,57.1873H368.4205a16.1208,16.1208,0,0,0,6.3849,10.3821A20.9323,20.9323,0,0,0,387.6309,71.4a21.6143,21.6143,0,0,0,16.1017-6.44l7.4394,8.55a25.069,25.069,0,0,1-10.1047,7.2182,40.0458,40.0458,0,0,1-30.981-1.4441,27.9774,27.9774,0,0,1-11.4931-10.8267A30.5555,30.5555,0,0,1,354.54,52.7451a31.0269,31.0269,0,0,1,3.942-15.6014A27.9716,27.9716,0,0,1,369.42,26.317a31.9159,31.9159,0,0,1,15.7681-3.8865,31.1165,31.1165,0,0,1,15.4908,3.8309,27.1367,27.1367,0,0,1,10.66,10.7711,32.6653,32.6653,0,0,1,3.8309,16.046Q415.17,54.5221,414.9478,57.1873Zm-41.0306-19.71A16.58,16.58,0,0,0,368.3093,47.97h33.6463a16.3708,16.3708,0,0,0-5.4412-10.4377,16.5475,16.5475,0,0,0-11.3264-3.9976A16.7437,16.7437,0,0,0,373.9172,37.4771Z"/>
        <path fill={color1} d="M469.5255,28.7038q6.9381,6.2759,6.94,18.9331V82.3937H463.3623V75.1756a16.14,16.14,0,0,1-7.2732,5.9408,28.4471,28.4471,0,0,1-11.382,2.0544,27.9084,27.9084,0,0,1-11.6594-2.2757,18.0038,18.0038,0,0,1-7.7173-6.33,16.0513,16.0513,0,0,1-2.7208-9.1614A15.701,15.701,0,0,1,428.55,52.5783q5.9392-4.8292,18.7108-4.83h15.324V46.86q0-6.2172-3.72-9.5494t-11.0485-3.3317a31.8919,31.8919,0,0,0-9.8273,1.5552,24.18,24.18,0,0,0-8.162,4.33l-5.4411-10.1052a33.7193,33.7193,0,0,1,11.2157-5.4407,49.9262,49.9262,0,0,1,13.88-1.8876Q462.5838,22.4305,469.5255,28.7038Zm-12.6588,41.975a12.9923,12.9923,0,0,0,5.7185-6.8291V56.965H448.2606q-11.9928,0-11.9929,7.8841a7.0755,7.0755,0,0,0,2.9983,5.9964q2.9982,2.2216,8.3282,2.22A17.9228,17.9228,0,0,0,456.8667,70.6788Z"/>
        <path fill={color1} d="M494.2322,0h13.88V82.3937h-13.88Z"/>
        <path fill={color2} d="M337.8846,175.2615v12.104H279.5872v-77.73H336.33v12.104H294.023v20.2094h37.5322V153.83H294.023v21.4311Z"/>
        <path fill={color2} d="M389.2983,187.3655l-15.2128-20.5429-15.3241,20.5429h-15.324l23.0969-30.0923-22.0975-29.205H359.872l14.5464,19.4323,14.547-19.4323h14.99l-22.2087,28.9827,23.2081,30.3146Z"/>
        <path fill={color2} d="M463.53,131.1776a27.2392,27.2392,0,0,1,10.716,10.66,34.4963,34.4963,0,0,1,0,31.8142,27.1208,27.1208,0,0,1-10.716,10.7156,31.4689,31.4689,0,0,1-15.49,3.7753q-11.9928,0-18.9887-7.9953v28.76h-13.88v-80.84h13.214v7.7729a21.4735,21.4735,0,0,1,8.4944-6.3289,28.697,28.697,0,0,1,11.16-2.11A31.4905,31.4905,0,0,1,463.53,131.1776Zm-4.4418,39.9751q4.94-5.1056,4.9415-13.4359,0-8.3277-4.9415-13.4358a16.7793,16.7793,0,0,0-12.6032-5.1082,17.8583,17.8583,0,0,0-8.9946,2.2767,16.4574,16.4574,0,0,0-6.3294,6.4956,21.6409,21.6409,0,0,0,0,19.5435,16.4471,16.4471,0,0,0,6.3294,6.4966,17.8475,17.8475,0,0,0,8.9946,2.2757A16.7719,16.7719,0,0,0,459.0883,171.1527Z"/>
        <path fill={color2} d="M546.8671,162.1591H500.34a16.1216,16.1216,0,0,0,6.385,10.3821A20.9327,20.9327,0,0,0,519.55,176.372a21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55A25.0678,25.0678,0,0,1,532.9866,185.7a40.0452,40.0452,0,0,1-30.981-1.444,27.9789,27.9789,0,0,1-11.4932-10.8267,30.5569,30.5569,0,0,1-4.0532-15.7126,31.027,31.027,0,0,1,3.9421-15.6014,27.9727,27.9727,0,0,1,10.9378-10.8267,31.9175,31.9175,0,0,1,15.7682-3.8864,31.1175,31.1175,0,0,1,15.4908,3.8308,27.1369,27.1369,0,0,1,10.66,10.7712,32.6653,32.6653,0,0,1,3.8309,16.046Q547.0889,159.4939,546.8671,162.1591Zm-41.0306-19.71a16.5792,16.5792,0,0,0-5.6079,10.4932h33.6462a16.37,16.37,0,0,0-5.4411-10.4376,16.547,16.547,0,0,0-11.3264-3.9976A16.7432,16.7432,0,0,0,505.8365,142.4489Z"/>
        <path fill={color2} d="M594.7263,127.4023v13.2135a17.704,17.704,0,0,0-3.2205-.3325q-8.1069,0-12.6588,4.7192-4.5544,4.7211-4.5529,13.6026v28.76h-13.88V128.0682h13.2141v8.6611Q579.6246,127.4027,594.7263,127.4023Z"/>
        <path fill={color2} d="M607.1638,115.91a8.1863,8.1863,0,0,1,0-11.8817,8.9157,8.9157,0,0,1,6.3294-2.3878,9.1707,9.1707,0,0,1,6.3294,2.2767,7.3393,7.3393,0,0,1,2.5541,5.7185,8.3757,8.3757,0,0,1-2.4986,6.1632,8.6475,8.6475,0,0,1-6.3849,2.498A8.91,8.91,0,0,1,607.1638,115.91Zm-.6659,12.1586h13.88v59.2973h-13.88Z"/>
        <path fill={color2} d="M694.1094,162.1591H647.582a16.1212,16.1212,0,0,0,6.385,10.3821,20.9325,20.9325,0,0,0,12.8255,3.8308,21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55A25.0685,25.0685,0,0,1,680.2289,185.7a40.0452,40.0452,0,0,1-30.981-1.444,27.9789,27.9789,0,0,1-11.4932-10.8267,30.5569,30.5569,0,0,1-4.0532-15.7126,31.027,31.027,0,0,1,3.9421-15.6014,27.9727,27.9727,0,0,1,10.9378-10.8267,31.9173,31.9173,0,0,1,15.7682-3.8864,31.1171,31.1171,0,0,1,15.4907,3.8308,27.1372,27.1372,0,0,1,10.66,10.7712,32.6653,32.6653,0,0,1,3.8309,16.046Q694.3312,159.4939,694.1094,162.1591Zm-41.0307-19.71a16.58,16.58,0,0,0-5.6078,10.4932h33.6462a16.37,16.37,0,0,0-5.4411-10.4376,16.547,16.547,0,0,0-11.3264-3.9976A16.7433,16.7433,0,0,0,653.0787,142.4489Z"/>
        <path fill={color2} d="M759.2914,133.9534q6.7715,6.5538,6.7735,19.4323v33.98h-13.88V155.1633q0-7.772-3.6641-11.715-3.6644-3.9415-10.4382-3.942-7.6621,0-12.104,4.6079t-4.4417,13.2691v29.9822h-13.88V128.0682h13.2141V135.73a21.698,21.698,0,0,1,8.6611-6.2177,31.1769,31.1769,0,0,1,11.77-2.11Q752.5169,127.4023,759.2914,133.9534Z"/>
        <path fill={color2} d="M794.9344,184.2561a28.5218,28.5218,0,0,1-11.437-10.8267,31.9916,31.9916,0,0,1,0-31.37,28.7408,28.7408,0,0,1,11.3815-10.7711,34.6756,34.6756,0,0,1,16.6008-3.8864,31.9992,31.9992,0,0,1,15.38,3.5529,23.0628,23.0628,0,0,1,9.9374,10.2164l-10.66,6.2187a16.9523,16.9523,0,0,0-6.3845-6.1631,18.6348,18.6348,0,0,0-21.2654,2.9982q-5.1087,5.053-5.1071,13.4914,0,8.44,5.0515,13.4915a18.7215,18.7215,0,0,0,21.321,2.9982,16.9339,16.9339,0,0,0,6.3845-6.1622l10.66,6.2178a24.0311,24.0311,0,0,1-10.0486,10.2719,31.3419,31.3419,0,0,1-15.2689,3.6086A34.7406,34.7406,0,0,1,794.9344,184.2561Z"/>
        <path fill={color2} d="M903.4238,162.1591H856.8975a16.12,16.12,0,0,0,6.3844,10.3821,20.933,20.933,0,0,0,12.8256,3.8308,21.6151,21.6151,0,0,0,16.1016-6.44l7.4394,8.55A25.07,25.07,0,0,1,889.5444,185.7a40.0466,40.0466,0,0,1-30.9816-1.444A27.9676,27.9676,0,0,1,847.07,173.4294a30.551,30.551,0,0,1-4.0532-15.7126,31.0218,31.0218,0,0,1,3.942-15.6014,27.9631,27.9631,0,0,1,10.9379-10.8267,31.9142,31.9142,0,0,1,15.7681-3.8864,31.1154,31.1154,0,0,1,15.49,3.8308,27.1372,27.1372,0,0,1,10.66,10.7712,32.6718,32.6718,0,0,1,3.8308,16.046Q903.6461,159.4939,903.4238,162.1591Zm-41.03-19.71a16.58,16.58,0,0,0-5.6074,10.4932h33.6463a16.3755,16.3755,0,0,0-5.4417-10.4376,16.5466,16.5466,0,0,0-11.3259-3.9976A16.7436,16.7436,0,0,0,862.3937,142.4489Z"/>
      </svg>
    </div>
  )
}

export const LogoFull = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#d37b1c'
  color3 = color3 ? color3 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 1154.7693 208.9077"
      >
        <title>RE Logo Full</title>
        <circle fill={color1} cx="104.4539" cy="104.4539" r="104.4539"/>
        <path fill={color3} d="M86.5437,141.3294,71.4773,119.7307a27.7692,27.7692,0,0,1-2.8447.1055H51.9861v21.4932H38.2893V67.5784H68.6326A40.83,40.83,0,0,1,85.3318,70.74,24.2829,24.2829,0,0,1,96.2366,79.8,25.3544,25.3544,0,0,1,100.03,93.8128a24.9931,24.9931,0,0,1-4.0566,14.3281,24.1274,24.1274,0,0,1-11.6421,8.956l16.9629,24.2325ZM81.5916,82.96Q76.9546,79.1678,68,79.1672H51.9861v29.3956H68q8.9538,0,13.5913-3.8458,4.6347-3.8451,4.6357-10.9042T81.5916,82.96Z"/>
        <path fill={color3} d="M170.6184,129.845v11.4844h-55.313V67.5784h53.8384V79.0628H129.0022V98.2376H164.613V109.511H129.0022v20.334Z"/>
        <path fill={color1} d="M330.4452,136.5461l-15.8794-22.764a29.25,29.25,0,0,1-2.9982.1112H294.023v22.6528H279.5872v-77.73h31.98a43.0329,43.0329,0,0,1,17.6,3.3317A25.5928,25.5928,0,0,1,340.661,71.697a26.7232,26.7232,0,0,1,3.9976,14.7688,26.341,26.341,0,0,1-4.2755,15.1012,25.4286,25.4286,0,0,1-12.27,9.4392l17.8781,25.54Zm-5.2194-61.5184q-4.8872-3.997-14.3246-3.9976H294.023v30.9815h16.8782q9.4369,0,14.3246-4.0532,4.8849-4.0527,4.8859-11.4926T325.2258,75.0277Z"/>
        <path fill={color1} d="M414.9478,111.34H368.4205a16.1208,16.1208,0,0,0,6.3849,10.3821,20.9323,20.9323,0,0,0,12.8255,3.8309,21.6143,21.6143,0,0,0,16.1017-6.44l7.4394,8.55a25.069,25.069,0,0,1-10.1047,7.2182,40.0458,40.0458,0,0,1-30.981-1.4441A27.9781,27.9781,0,0,1,358.5932,122.61a30.5555,30.5555,0,0,1-4.0532-15.7125,31.0269,31.0269,0,0,1,3.942-15.6014A27.9725,27.9725,0,0,1,369.42,80.4693a31.9169,31.9169,0,0,1,15.7681-3.8864,31.1165,31.1165,0,0,1,15.4908,3.8309,27.136,27.136,0,0,1,10.66,10.7711,32.6653,32.6653,0,0,1,3.8309,16.046Q415.17,108.6745,414.9478,111.34ZM373.9172,91.63a16.5792,16.5792,0,0,0-5.6079,10.4933h33.6463a16.37,16.37,0,0,0-5.4412-10.4377,16.547,16.547,0,0,0-11.3264-3.9976A16.7437,16.7437,0,0,0,373.9172,91.63Z"/>
        <path fill={color1} d="M469.5255,82.8562q6.9381,6.2758,6.94,18.9331v34.7568H463.3623V129.328a16.14,16.14,0,0,1-7.2732,5.9408,28.4471,28.4471,0,0,1-11.382,2.0544,27.9084,27.9084,0,0,1-11.6594-2.2757,18.0038,18.0038,0,0,1-7.7173-6.33,16.0513,16.0513,0,0,1-2.7208-9.1614,15.701,15.701,0,0,1,5.9408-12.8255q5.9392-4.8292,18.7108-4.83h15.324v-.8882q0-6.2172-3.72-9.5494t-11.0485-3.3317a31.8889,31.8889,0,0,0-9.8273,1.5552,24.18,24.18,0,0,0-8.162,4.33l-5.4411-10.1052a33.7174,33.7174,0,0,1,11.2157-5.4407,49.9262,49.9262,0,0,1,13.88-1.8876Q462.5838,76.5829,469.5255,82.8562Zm-12.6588,41.975a12.9923,12.9923,0,0,0,5.7185-6.8291v-6.8847H448.2606q-11.9928,0-11.9929,7.8841a7.0755,7.0755,0,0,0,2.9983,5.9964q2.9982,2.2216,8.3282,2.22A17.9228,17.9228,0,0,0,456.8667,124.8312Z"/>
        <path fill={color1} d="M494.2322,54.1524h13.88v82.3937h-13.88Z"/>
        <path fill={color2} d="M589.0078,124.4414v12.104H530.71v-77.73h56.7432v12.104H545.1462V91.1286h37.5322V103.01H545.1462v21.4311Z"/>
        <path fill={color2} d="M640.4215,136.5454l-15.2129-20.5428-15.324,20.5428h-15.324l23.0969-30.0922L595.56,77.2481h15.4352L625.5416,96.68l14.547-19.4323h14.99L632.87,106.2309l23.2081,30.3145Z"/>
        <path fill={color2} d="M714.6533,80.3575a27.2408,27.2408,0,0,1,10.716,10.66,31.7586,31.7586,0,0,1,3.8865,15.8793,32.0626,32.0626,0,0,1-3.8865,15.9349,27.1212,27.1212,0,0,1-10.716,10.7155,31.4679,31.4679,0,0,1-15.49,3.7753q-11.9928,0-18.9887-7.9952v28.76h-13.88v-80.84h13.2141v7.773a21.4726,21.4726,0,0,1,8.4944-6.3289,28.6952,28.6952,0,0,1,11.16-2.11A31.4905,31.4905,0,0,1,714.6533,80.3575Zm-4.4418,39.9752q4.94-5.1057,4.9415-13.4359,0-8.3277-4.9415-13.4359a16.78,16.78,0,0,0-12.6032-5.1081,17.857,17.857,0,0,0-8.9946,2.2767,16.4568,16.4568,0,0,0-6.3294,6.4956,21.6407,21.6407,0,0,0,0,19.5434,16.4473,16.4473,0,0,0,6.3294,6.4967,17.8471,17.8471,0,0,0,8.9946,2.2756A16.7718,16.7718,0,0,0,710.2115,120.3327Z"/>
        <path fill={color2} d="M797.99,111.339H751.4629a16.1213,16.1213,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55A25.07,25.07,0,0,1,784.11,134.88a40.0464,40.0464,0,0,1-30.981-1.444,27.98,27.98,0,0,1-11.4932-10.8267,30.5571,30.5571,0,0,1-4.0532-15.7126,31.027,31.027,0,0,1,3.9421-15.6014,27.9713,27.9713,0,0,1,10.9378-10.8267,31.9176,31.9176,0,0,1,15.7682-3.8865,31.1162,31.1162,0,0,1,15.4907,3.8309,27.1375,27.1375,0,0,1,10.66,10.7711,32.6657,32.6657,0,0,1,3.8309,16.0461Q798.2121,108.6738,797.99,111.339ZM756.96,91.6289a16.579,16.579,0,0,0-5.6079,10.4932H784.998a16.3707,16.3707,0,0,0-5.4411-10.4377,16.5475,16.5475,0,0,0-11.3264-3.9976A16.7429,16.7429,0,0,0,756.96,91.6289Z"/>
        <path fill={color2} d="M845.85,76.5822V89.7958a17.704,17.704,0,0,0-3.2205-.3325q-8.1069,0-12.6588,4.7191-4.5544,4.7212-4.5529,13.6026v28.76h-13.88V77.2481h13.2141v8.6612Q830.7478,76.5827,845.85,76.5822Z"/>
        <path fill={color2} d="M858.287,65.09a8.1863,8.1863,0,0,1,0-11.8817,8.9158,8.9158,0,0,1,6.3294-2.3879,9.1709,9.1709,0,0,1,6.3294,2.2767,7.34,7.34,0,0,1,2.554,5.7186,8.3752,8.3752,0,0,1-2.4985,6.1631,8.6471,8.6471,0,0,1-6.3849,2.498A8.9106,8.9106,0,0,1,858.287,65.09Zm-.6659,12.1585h13.88v59.2973h-13.88Z"/>
        <path fill={color2} d="M945.2326,111.339H898.7052a16.1205,16.1205,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55a25.07,25.07,0,0,1-10.1047,7.2181,40.0464,40.0464,0,0,1-30.981-1.444,27.98,27.98,0,0,1-11.4932-10.8267,30.5571,30.5571,0,0,1-4.0532-15.7126,31.027,31.027,0,0,1,3.9421-15.6014,27.9713,27.9713,0,0,1,10.9378-10.8267,31.9173,31.9173,0,0,1,15.7682-3.8865,31.1162,31.1162,0,0,1,15.4907,3.8309,27.1375,27.1375,0,0,1,10.66,10.7711,32.6657,32.6657,0,0,1,3.8309,16.0461Q945.4544,108.6738,945.2326,111.339Zm-41.0307-19.71a16.5793,16.5793,0,0,0-5.6078,10.4932H932.24a16.3707,16.3707,0,0,0-5.4411-10.4377,16.5475,16.5475,0,0,0-11.3264-3.9976A16.7434,16.7434,0,0,0,904.2019,91.6289Z"/>
        <path fill={color2} d="M1010.4146,83.1334q6.7715,6.5538,6.7735,19.4323v33.98h-13.88V104.3432q0-7.7718-3.6641-11.7149-3.6644-3.9415-10.4382-3.9421-7.6623,0-12.104,4.608T972.66,106.5633v29.9821H958.78V77.2481h13.214V84.91a21.6991,21.6991,0,0,1,8.6612-6.2177,31.175,31.175,0,0,1,11.77-2.11Q1003.64,76.5822,1010.4146,83.1334Z"/>
        <path fill={color2} d="M1046.0576,133.4361a28.5225,28.5225,0,0,1-11.437-10.8267,31.9916,31.9916,0,0,1,0-31.37,28.74,28.74,0,0,1,11.3814-10.7711,34.6763,34.6763,0,0,1,16.6009-3.8865,32,32,0,0,1,15.38,3.553,23.0628,23.0628,0,0,1,9.9374,10.2164L1077.26,96.57a16.9524,16.9524,0,0,0-6.3844-6.1632,18.6352,18.6352,0,0,0-21.2654,2.9983q-5.1087,5.053-5.1071,13.4914,0,8.44,5.0515,13.4914a18.7215,18.7215,0,0,0,21.321,2.9982,16.9326,16.9326,0,0,0,6.3844-6.1621l10.66,6.2177a24.0308,24.0308,0,0,1-10.0486,10.272,31.3419,31.3419,0,0,1-15.2689,3.6085A34.7406,34.7406,0,0,1,1046.0576,133.4361Z"/>
        <path fill={color2} d="M1154.547,111.339h-46.5263a16.12,16.12,0,0,0,6.3844,10.3821,20.9323,20.9323,0,0,0,12.8255,3.8309,21.6154,21.6154,0,0,0,16.1017-6.44l7.4394,8.55a25.0715,25.0715,0,0,1-10.1042,7.2181,40.0476,40.0476,0,0,1-30.9815-1.444,27.9683,27.9683,0,0,1-11.4926-10.8267,30.5513,30.5513,0,0,1-4.0532-15.7126,31.0218,31.0218,0,0,1,3.942-15.6014A27.9617,27.9617,0,0,1,1109.02,80.4687a31.9142,31.9142,0,0,1,15.7681-3.8865,31.1146,31.1146,0,0,1,15.49,3.8309,27.1375,27.1375,0,0,1,10.66,10.7711,32.6721,32.6721,0,0,1,3.8308,16.0461Q1154.7693,108.6738,1154.547,111.339Zm-41.03-19.71a16.5789,16.5789,0,0,0-5.6074,10.4932h33.6463a16.376,16.376,0,0,0-5.4417-10.4377,16.5471,16.5471,0,0,0-11.3259-3.9976A16.7437,16.7437,0,0,0,1113.5169,91.6289Z"/>
      </svg>
    </div>
  )
}

export const LogoMark = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 208.9077 208.9077"
      >
        <title>RE Logomark</title>
        <circle fill={color1} cx="104.4539" cy="104.4539" r="104.4539"/>
        <circle fill={color1} cx="104.4539" cy="104.4539" r="104.4539"/>
        <path fill={color2} d="M86.5437,141.3294,71.4773,119.7307a27.7692,27.7692,0,0,1-2.8447.1055H51.9861v21.4932H38.2893V67.5784H68.6326A40.83,40.83,0,0,1,85.3318,70.74,24.2829,24.2829,0,0,1,96.2366,79.8,25.3544,25.3544,0,0,1,100.03,93.8128a24.9931,24.9931,0,0,1-4.0566,14.3281,24.1274,24.1274,0,0,1-11.6421,8.956l16.9629,24.2325ZM81.5916,82.96Q76.9546,79.1678,68,79.1672H51.9861v29.3956H68q8.9538,0,13.5913-3.8458,4.6347-3.8451,4.6357-10.9042T81.5916,82.96Z"/>
        <path fill={color2} d="M170.6184,129.845v11.4844h-55.313V67.5784h53.8384V79.0628H129.0022V98.2376H164.613V109.511H129.0022v20.334Z"/>
      </svg>
    </div>
  )
}

export const LogoType = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 875.1821 107.2677"
      >
        <title>RE Logotype</title>
        <circle fill={color1} cx="104.4539" cy="104.4539" r="104.4539"/>
        <path fill={color1} d="M50.8579,85.7261,34.9786,62.9621a29.28,29.28,0,0,1-2.9982.1112H14.4358V85.7261H0V7.9959H31.98a43.0324,43.0324,0,0,1,17.6,3.3317A25.5931,25.5931,0,0,1,61.0738,20.877a26.7228,26.7228,0,0,1,3.9976,14.7687,26.3408,26.3408,0,0,1-4.2755,15.1012,25.4292,25.4292,0,0,1-12.27,9.4393l17.8781,25.54ZM45.6386,24.2077Q40.7515,20.2105,31.314,20.21H14.4358V51.1916H31.314q9.437,0,14.3246-4.0532,4.8849-4.0527,4.8859-11.4927Q50.5245,28.2074,45.6386,24.2077Z"/>
        <path fill={color1} d="M135.3606,60.52H88.8332a16.1216,16.1216,0,0,0,6.385,10.3821,20.9327,20.9327,0,0,0,12.8255,3.8308,21.6144,21.6144,0,0,0,16.1016-6.44l7.4395,8.55A25.0685,25.0685,0,0,1,121.48,84.0607a36.7922,36.7922,0,0,1-13.7693,2.4425,36.62,36.62,0,0,1-17.2117-3.8865A27.9789,27.9789,0,0,1,79.0059,71.79a30.5569,30.5569,0,0,1-4.0532-15.7126A31.027,31.027,0,0,1,78.8948,40.476,27.9727,27.9727,0,0,1,89.8326,29.6493a31.9175,31.9175,0,0,1,15.7682-3.8864,31.1175,31.1175,0,0,1,15.4908,3.8308,27.1376,27.1376,0,0,1,10.66,10.7712,32.6653,32.6653,0,0,1,3.8309,16.046Q135.5824,57.8545,135.3606,60.52ZM94.33,40.81a16.5792,16.5792,0,0,0-5.6079,10.4932h33.6462a16.37,16.37,0,0,0-5.4411-10.4376,16.547,16.547,0,0,0-11.3264-3.9976A16.7428,16.7428,0,0,0,94.33,40.81Z"/>
        <path fill={color1} d="M189.9382,32.0362q6.9383,6.2758,6.94,18.933V85.7261H183.7751V78.5079a16.14,16.14,0,0,1-7.2732,5.9409,28.4471,28.4471,0,0,1-11.382,2.0544,27.9081,27.9081,0,0,1-11.6594-2.2757,18.0032,18.0032,0,0,1-7.7173-6.33,16.0514,16.0514,0,0,1-2.7209-9.1614,15.7008,15.7008,0,0,1,5.9409-12.8255q5.9392-4.8292,18.7108-4.83h15.324v-.8882q0-6.2172-3.72-9.5494t-11.0485-3.3317a31.8889,31.8889,0,0,0-9.8273,1.5552,24.1818,24.1818,0,0,0-8.162,4.33l-5.4412-10.1052a33.7188,33.7188,0,0,1,11.2158-5.4406,49.9262,49.9262,0,0,1,13.8805-1.8876Q182.9964,25.7629,189.9382,32.0362ZM177.28,74.0111a12.9924,12.9924,0,0,0,5.7185-6.8291V60.2974H168.6734q-11.9929,0-11.9929,7.884a7.0757,7.0757,0,0,0,2.9982,5.9965q2.9982,2.2217,8.3282,2.22A17.9223,17.9223,0,0,0,177.28,74.0111Z"/>
        <path fill={color1} d="M214.6449,3.3324h13.88V85.7261h-13.88Z"/>
        <path fill={color2} d="M309.4206,73.6214v12.104H251.1232V7.9952h56.7432v12.104H265.559V40.3086h37.5322V52.19H265.559V73.6214Z"/>
        <path fill={color2} d="M360.8343,85.7254,345.6214,65.1826l-15.324,20.5428h-15.324L338.07,55.6332,315.9728,26.4281H331.408L345.9544,45.86l14.5469-19.4323h14.9906L353.2832,55.4108l23.2081,30.3146Z"/>
        <path fill={color2} d="M435.066,29.5375a27.24,27.24,0,0,1,10.7161,10.66,31.7581,31.7581,0,0,1,3.8865,15.8793,32.0619,32.0619,0,0,1-3.8865,15.9348A27.121,27.121,0,0,1,435.066,82.7272a31.4683,31.4683,0,0,1-15.49,3.7753q-11.9928,0-18.9887-7.9952v28.76h-13.88v-80.84h13.2141V34.201a21.4724,21.4724,0,0,1,8.4944-6.3288,28.6948,28.6948,0,0,1,11.16-2.11A31.49,31.49,0,0,1,435.066,29.5375Zm-4.4417,39.9751q4.94-5.1056,4.9414-13.4358,0-8.3277-4.9414-13.4359a16.7793,16.7793,0,0,0-12.6032-5.1082,17.8581,17.8581,0,0,0-8.9946,2.2767,16.4574,16.4574,0,0,0-6.3294,6.4956,19.8756,19.8756,0,0,0-2.3318,9.7718,19.8668,19.8668,0,0,0,2.3318,9.7717,16.4457,16.4457,0,0,0,6.3294,6.4966,17.8462,17.8462,0,0,0,8.9946,2.2757A16.7719,16.7719,0,0,0,430.6243,69.5126Z"/>
        <path fill={color2} d="M518.4031,60.519H471.8757a16.1205,16.1205,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6141,21.6141,0,0,0,16.1016-6.44l7.44,8.55A25.07,25.07,0,0,1,504.5226,84.06a40.0458,40.0458,0,0,1-30.981-1.4441,27.9789,27.9789,0,0,1-11.4932-10.8267,30.5565,30.5565,0,0,1-4.0532-15.7125,31.027,31.027,0,0,1,3.9421-15.6014,27.9723,27.9723,0,0,1,10.9378-10.8268,31.9173,31.9173,0,0,1,15.7682-3.8864,31.1162,31.1162,0,0,1,15.4907,3.8309,27.1363,27.1363,0,0,1,10.66,10.7711,32.6653,32.6653,0,0,1,3.8309,16.046Q518.6249,57.8538,518.4031,60.519Zm-41.0307-19.71a16.58,16.58,0,0,0-5.6078,10.4933h33.6462A16.37,16.37,0,0,0,499.97,40.8644a16.547,16.547,0,0,0-11.3264-3.9976A16.7433,16.7433,0,0,0,477.3724,40.8088Z"/>
        <path fill={color2} d="M566.2623,25.7622V38.9757a17.7034,17.7034,0,0,0-3.2206-.3324q-8.1069,0-12.6587,4.7191-4.5544,4.7213-4.5529,13.6026v28.76H531.95V26.4281h13.214v8.6612Q551.1607,25.7628,566.2623,25.7622Z"/>
        <path fill={color2} d="M578.7,14.27a7.7941,7.7941,0,0,1-2.5541-5.9409A7.7969,7.7969,0,0,1,578.7,2.3879,8.9152,8.9152,0,0,1,585.0292,0a9.17,9.17,0,0,1,6.3293,2.2767,7.34,7.34,0,0,1,2.5541,5.7185,8.3756,8.3756,0,0,1-2.4985,6.1632,8.6475,8.6475,0,0,1-6.3849,2.498A8.91,8.91,0,0,1,578.7,14.27Zm-.666,12.1585h13.88V85.7254h-13.88Z"/>
        <path fill={color2} d="M665.6454,60.519H619.118a16.1205,16.1205,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6141,21.6141,0,0,0,16.1016-6.44l7.4394,8.55A25.0694,25.0694,0,0,1,651.7649,84.06a40.0461,40.0461,0,0,1-30.9811-1.4441,27.9793,27.9793,0,0,1-11.4931-10.8267,30.5565,30.5565,0,0,1-4.0532-15.7125,31.0269,31.0269,0,0,1,3.942-15.6014,27.9732,27.9732,0,0,1,10.9379-10.8268,31.9172,31.9172,0,0,1,15.7681-3.8864,31.1163,31.1163,0,0,1,15.4908,3.8309,27.1363,27.1363,0,0,1,10.66,10.7711,32.6653,32.6653,0,0,1,3.8309,16.046Q665.8672,57.8538,665.6454,60.519Zm-41.0307-19.71a16.58,16.58,0,0,0-5.6079,10.4933h33.6463a16.3707,16.3707,0,0,0-5.4411-10.4377,16.5471,16.5471,0,0,0-11.3265-3.9976A16.7432,16.7432,0,0,0,624.6147,40.8088Z"/>
        <path fill={color2} d="M730.8274,32.3134q6.7714,6.5538,6.7735,19.4323v33.98H723.72V53.5232q0-7.772-3.6641-11.715-3.6645-3.9414-10.4382-3.942-7.6623,0-12.104,4.608t-4.4417,13.2691V85.7254h-13.88V26.4281h13.214V34.09a21.6981,21.6981,0,0,1,8.6612-6.2177,31.175,31.175,0,0,1,11.7705-2.11Q724.0529,25.7622,730.8274,32.3134Z"/>
        <path fill={color2} d="M766.47,82.616a28.5212,28.5212,0,0,1-11.437-10.8267,30.24,30.24,0,0,1-4.1088-15.7125,29.9562,29.9562,0,0,1,4.1088-15.657,28.7407,28.7407,0,0,1,11.3814-10.7712,34.6761,34.6761,0,0,1,16.6008-3.8864,32,32,0,0,1,15.38,3.553,23.0626,23.0626,0,0,1,9.9374,10.2163l-10.66,6.2188a16.9523,16.9523,0,0,0-6.3844-6.1632,18.6348,18.6348,0,0,0-21.2654,2.9982q-5.1087,5.0532-5.1072,13.4915,0,8.44,5.0516,13.4914a18.7215,18.7215,0,0,0,21.321,2.9982,16.9341,16.9341,0,0,0,6.3844-6.1621l10.66,6.2177a24.0311,24.0311,0,0,1-10.0486,10.2719,31.3423,31.3423,0,0,1-15.269,3.6086A34.7405,34.7405,0,0,1,766.47,82.616Z"/>
        <path fill={color2} d="M874.96,60.519H828.4335a16.12,16.12,0,0,0,6.3844,10.3821,20.9323,20.9323,0,0,0,12.8255,3.8309,21.6152,21.6152,0,0,0,16.1017-6.44l7.4394,8.55A25.0707,25.0707,0,0,1,861.08,84.06a40.0471,40.0471,0,0,1-30.9815-1.4441,27.9676,27.9676,0,0,1-11.4926-10.8267,30.5507,30.5507,0,0,1-4.0532-15.7125,31.0218,31.0218,0,0,1,3.942-15.6014,27.9627,27.9627,0,0,1,10.9379-10.8268,31.9142,31.9142,0,0,1,15.7681-3.8864,31.1146,31.1146,0,0,1,15.49,3.8309,27.136,27.136,0,0,1,10.66,10.7711,32.6707,32.6707,0,0,1,3.8309,16.046Q875.1821,57.8538,874.96,60.519Zm-41.03-19.71a16.5791,16.5791,0,0,0-5.6074,10.4933h33.6463a16.376,16.376,0,0,0-5.4417-10.4377,16.5466,16.5466,0,0,0-11.3259-3.9976A16.7436,16.7436,0,0,0,833.93,40.8088Z"/>
      </svg>
    </div>
  )
}

export const LogoFullCutout = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#d37b1c'
  color3 = color3 ? color3 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 1154.7693 208.9077"
      >
        <title>RE Logo Full Cutout</title>
        <path fill={color3} d="M104.4539,0A104.4539,104.4539,0,1,0,208.9077,104.4539,104.4539,104.4539,0,0,0,104.4539,0Zm-17.91,141.3293L71.4773,119.7307a27.7692,27.7692,0,0,1-2.8447.1055H51.9861v21.4931H38.2893V67.5784H68.6326A40.83,40.83,0,0,1,85.3318,70.74,24.2827,24.2827,0,0,1,96.2366,79.8,25.3548,25.3548,0,0,1,100.03,93.8127a24.9932,24.9932,0,0,1-4.0566,14.3282,24.1274,24.1274,0,0,1-11.6421,8.956l16.9629,24.2324Zm84.0747,0h-55.313V67.5784h53.8384V79.0627H129.0022V98.2375H164.613V109.511H129.0022v20.334h41.6162Z"/>
        <path fill={color3} d="M81.5916,82.96Q76.9545,79.1678,68,79.1672H51.9861v29.3955H68q8.9539,0,13.5914-3.8457,4.6347-3.8451,4.6357-10.9043Q86.2273,86.7552,81.5916,82.96Z"/>
        <path fill={color1} d="M330.4451,136.5461l-15.8793-22.764a29.25,29.25,0,0,1-2.9982.1112H294.023v22.6528H279.5872v-77.73h31.98a43.0321,43.0321,0,0,1,17.6,3.3317A25.5931,25.5931,0,0,1,340.661,71.697a26.7232,26.7232,0,0,1,3.9976,14.7688,26.341,26.341,0,0,1-4.2755,15.1012,25.4292,25.4292,0,0,1-12.27,9.4392l17.8781,25.54Zm-5.2193-61.5184Q320.3386,71.03,310.9012,71.03H294.023v30.9815h16.8782q9.4369,0,14.3246-4.0532,4.8849-4.0527,4.8859-11.4926T325.2258,75.0277Z"/>
        <path fill={color1} d="M414.9478,111.34H368.42a16.1213,16.1213,0,0,0,6.385,10.3821,20.9321,20.9321,0,0,0,12.8255,3.8309,21.6143,21.6143,0,0,0,16.1017-6.44l7.4394,8.55a25.069,25.069,0,0,1-10.1047,7.2182,40.0458,40.0458,0,0,1-30.981-1.4441A27.9789,27.9789,0,0,1,358.5931,122.61a30.5565,30.5565,0,0,1-4.0532-15.7125A31.0274,31.0274,0,0,1,358.482,91.296,27.9729,27.9729,0,0,1,369.42,80.4693a31.9169,31.9169,0,0,1,15.7681-3.8864,31.1165,31.1165,0,0,1,15.4908,3.8309,27.136,27.136,0,0,1,10.66,10.7711,32.6653,32.6653,0,0,1,3.8309,16.046Q415.17,108.6745,414.9478,111.34ZM373.9172,91.63a16.5792,16.5792,0,0,0-5.6079,10.4933h33.6463a16.37,16.37,0,0,0-5.4412-10.4377,16.547,16.547,0,0,0-11.3264-3.9976A16.7432,16.7432,0,0,0,373.9172,91.63Z"/>
        <path fill={color1} d="M469.5255,82.8562q6.9381,6.2758,6.94,18.9331v34.7568H463.3623V129.328a16.14,16.14,0,0,1-7.2732,5.9408,28.4471,28.4471,0,0,1-11.382,2.0544,27.9084,27.9084,0,0,1-11.6594-2.2757,18.0032,18.0032,0,0,1-7.7173-6.33,16.0513,16.0513,0,0,1-2.7208-9.1614,15.7008,15.7008,0,0,1,5.9408-12.8255q5.9392-4.8292,18.7108-4.83h15.324v-.8882q0-6.2172-3.72-9.5494t-11.0485-3.3317a31.8889,31.8889,0,0,0-9.8273,1.5552,24.18,24.18,0,0,0-8.162,4.33L424.386,83.9112a33.7189,33.7189,0,0,1,11.2158-5.4407,49.9262,49.9262,0,0,1,13.88-1.8876Q462.5836,76.5829,469.5255,82.8562Zm-12.6588,41.975a12.9927,12.9927,0,0,0,5.7185-6.8291v-6.8847H448.2606q-11.9928,0-11.9929,7.8841a7.0755,7.0755,0,0,0,2.9983,5.9964q2.9982,2.2216,8.3281,2.22A17.9229,17.9229,0,0,0,456.8667,124.8312Z"/>
        <path fill={color1} d="M494.2322,54.1524h13.88v82.3937h-13.88Z"/>
        <path fill={color2} d="M589.0078,124.4414v12.104H530.71v-77.73h56.7432v12.104H545.1462V91.1286h37.5322V103.01H545.1462v21.4311Z"/>
        <path fill={color2} d="M640.4215,136.5454l-15.2129-20.5428-15.324,20.5428h-15.324l23.0969-30.0922L595.56,77.2481h15.4352L625.5416,96.68l14.5469-19.4323h14.9906L632.87,106.2309l23.2081,30.3145Z"/>
        <path fill={color2} d="M714.6533,80.3575a27.24,27.24,0,0,1,10.716,10.66,31.7586,31.7586,0,0,1,3.8865,15.8793,32.0626,32.0626,0,0,1-3.8865,15.9349,27.1212,27.1212,0,0,1-10.716,10.7155,31.4679,31.4679,0,0,1-15.49,3.7753q-11.9928,0-18.9887-7.9952v28.76h-13.88v-80.84h13.2141v7.773a21.4726,21.4726,0,0,1,8.4944-6.3289,28.6948,28.6948,0,0,1,11.16-2.11A31.4905,31.4905,0,0,1,714.6533,80.3575Zm-4.4418,39.9751q4.94-5.1056,4.9415-13.4358,0-8.3277-4.9415-13.4359a16.78,16.78,0,0,0-12.6032-5.1082,17.8571,17.8571,0,0,0-8.9946,2.2768,16.4561,16.4561,0,0,0-6.3294,6.4956,21.6407,21.6407,0,0,0,0,19.5434,16.4467,16.4467,0,0,0,6.3294,6.4967,17.8471,17.8471,0,0,0,8.9946,2.2756A16.7715,16.7715,0,0,0,710.2115,120.3326Z"/>
        <path fill={color2} d="M797.99,111.339H751.4629a16.1209,16.1209,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55A25.07,25.07,0,0,1,784.11,134.88a40.0464,40.0464,0,0,1-30.981-1.444,27.98,27.98,0,0,1-11.4932-10.8267,30.5571,30.5571,0,0,1-4.0532-15.7126,31.027,31.027,0,0,1,3.9421-15.6014,27.9713,27.9713,0,0,1,10.9378-10.8267,31.9163,31.9163,0,0,1,15.7682-3.8865,31.1162,31.1162,0,0,1,15.4907,3.8309,27.1375,27.1375,0,0,1,10.66,10.7711,32.6657,32.6657,0,0,1,3.8309,16.0461Q798.2121,108.6738,797.99,111.339ZM756.96,91.6289a16.5793,16.5793,0,0,0-5.6078,10.4932H784.998a16.3707,16.3707,0,0,0-5.4411-10.4377,16.5475,16.5475,0,0,0-11.3264-3.9976A16.7434,16.7434,0,0,0,756.96,91.6289Z"/>
        <path fill={color2} d="M845.85,76.5822V89.7958a17.704,17.704,0,0,0-3.2205-.3325q-8.1069,0-12.6588,4.7191-4.5544,4.7212-4.5529,13.6026v28.76h-13.88V77.2481h13.2141v8.6612Q830.7478,76.5827,845.85,76.5822Z"/>
        <path fill={color2} d="M858.287,65.09a8.1863,8.1863,0,0,1,0-11.8817,8.9158,8.9158,0,0,1,6.3294-2.3879,9.1709,9.1709,0,0,1,6.3294,2.2767,7.34,7.34,0,0,1,2.554,5.7186,8.3752,8.3752,0,0,1-2.4985,6.1631,8.6471,8.6471,0,0,1-6.3849,2.498A8.9106,8.9106,0,0,1,858.287,65.09Zm-.6659,12.1585h13.88v59.2973h-13.88Z"/>
        <path fill={color2} d="M945.2326,111.339H898.7052a16.1205,16.1205,0,0,0,6.385,10.3821,20.9319,20.9319,0,0,0,12.8255,3.8309,21.6144,21.6144,0,0,0,16.1016-6.44l7.44,8.55a25.0711,25.0711,0,0,1-10.1047,7.2181,40.0464,40.0464,0,0,1-30.981-1.444,27.98,27.98,0,0,1-11.4932-10.8267,30.5571,30.5571,0,0,1-4.0532-15.7126,31.0269,31.0269,0,0,1,3.942-15.6014,27.9723,27.9723,0,0,1,10.9379-10.8267,31.9163,31.9163,0,0,1,15.7682-3.8865,31.1162,31.1162,0,0,1,15.4907,3.8309,27.1375,27.1375,0,0,1,10.66,10.7711,32.6657,32.6657,0,0,1,3.8309,16.0461Q945.4544,108.6738,945.2326,111.339Zm-41.0307-19.71a16.58,16.58,0,0,0-5.6079,10.4932H932.24a16.3712,16.3712,0,0,0-5.4411-10.4377,16.5475,16.5475,0,0,0-11.3264-3.9976A16.7434,16.7434,0,0,0,904.2019,91.6289Z"/>
        <path fill={color2} d="M1010.4146,83.1334q6.7715,6.5538,6.7735,19.4323v33.98h-13.88V104.3432q0-7.7718-3.6641-11.7149-3.6645-3.9415-10.4382-3.9421-7.6623,0-12.104,4.608T972.66,106.5633v29.9821H958.78V77.2481h13.214V84.91a21.6991,21.6991,0,0,1,8.6612-6.2177,31.175,31.175,0,0,1,11.77-2.11Q1003.64,76.5822,1010.4146,83.1334Z"/>
        <path fill={color2} d="M1046.0576,133.4361a28.5225,28.5225,0,0,1-11.437-10.8267,31.9916,31.9916,0,0,1,0-31.37,28.74,28.74,0,0,1,11.3814-10.7711,34.6752,34.6752,0,0,1,16.6009-3.8865,32,32,0,0,1,15.38,3.553,23.0628,23.0628,0,0,1,9.9374,10.2164L1077.26,96.57a16.9524,16.9524,0,0,0-6.3844-6.1632,18.6348,18.6348,0,0,0-21.2654,2.9982q-5.1087,5.0532-5.1072,13.4915,0,8.44,5.0516,13.4914a18.7215,18.7215,0,0,0,21.321,2.9982,16.9334,16.9334,0,0,0,6.3844-6.1621l10.66,6.2177a24.0308,24.0308,0,0,1-10.0486,10.272,31.3419,31.3419,0,0,1-15.2689,3.6085A34.7418,34.7418,0,0,1,1046.0576,133.4361Z"/>
        <path fill={color2} d="M1154.547,111.339h-46.5263a16.12,16.12,0,0,0,6.3844,10.3821,20.9323,20.9323,0,0,0,12.8255,3.8309,21.6154,21.6154,0,0,0,16.1017-6.44l7.4394,8.55a25.0715,25.0715,0,0,1-10.1042,7.2181,40.0476,40.0476,0,0,1-30.9815-1.444,27.9683,27.9683,0,0,1-11.4926-10.8267,30.5513,30.5513,0,0,1-4.0532-15.7126,31.0218,31.0218,0,0,1,3.942-15.6014A27.9617,27.9617,0,0,1,1109.02,80.4687a31.9132,31.9132,0,0,1,15.7681-3.8865,31.1146,31.1146,0,0,1,15.49,3.8309,27.1367,27.1367,0,0,1,10.66,10.7711,32.6711,32.6711,0,0,1,3.8309,16.0461Q1154.7693,108.6738,1154.547,111.339Zm-41.03-19.71a16.5789,16.5789,0,0,0-5.6074,10.4932h33.6463a16.376,16.376,0,0,0-5.4417-10.4377,16.5471,16.5471,0,0,0-11.3259-3.9976A16.7437,16.7437,0,0,0,1113.5169,91.6289Z"/>
      </svg>
    </div>
  )
}

export const LogoMarkCutout = (props) => {
  let { width, height, color1, color2, color3, style } = props;
  color1 = color1 ? color1 : '#0547db'
  color2 = color2 ? color2 : '#ffffff'

  return (
    <div style={{...style}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 208.9077 208.9077"
      >
        <title>RE Logomark Cutout</title>
        <path fill={color1} d="M104.4539,0A104.4539,104.4539,0,1,0,208.9077,104.4539,104.4539,104.4539,0,0,0,104.4539,0Zm-17.91,141.3293L71.4773,119.7307a27.7692,27.7692,0,0,1-2.8447.1055H51.9861v21.4931H38.2893V67.5784H68.6326A40.83,40.83,0,0,1,85.3318,70.74,24.2827,24.2827,0,0,1,96.2366,79.8,25.3548,25.3548,0,0,1,100.03,93.8127a24.9932,24.9932,0,0,1-4.0566,14.3282,24.1274,24.1274,0,0,1-11.6421,8.956l16.9629,24.2324Zm84.0747,0h-55.313V67.5784h53.8384V79.0627H129.0022V98.2375H164.613V109.511H129.0022v20.334h41.6162Z"/>
        <path fill={color1}d="M81.5916,82.96Q76.9545,79.1678,68,79.1672H51.9861v29.3955H68q8.9539,0,13.5914-3.8457,4.6347-3.8451,4.6357-10.9043Q86.2273,86.7552,81.5916,82.96Z"/>
      </svg>
    </div>
  )
}

