import logo from './logo.svg';
import './App.css';
import Landing from './components/landing/Landing';
import { ThemeProvider } from '@emotion/react';
import { responsiveFontSizes } from '@mui/material';
import { theme } from './theme';
import { routes } from './routes';
import { useRoutes } from 'react-router-dom';

const responsiveTheme = responsiveFontSizes(theme);

function App() {
  const routeContent = useRoutes(routes);
  return (
    <ThemeProvider theme={responsiveTheme}>
      {routeContent}
    </ThemeProvider>
  );
}

export default App;
