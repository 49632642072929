import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const MethodsPage = Loadable(lazy(() => import('./pages/MethodsPage')));
const InClassPage = Loadable(lazy(() => import('./pages/InClassPage')));
const InternsPage = Loadable(lazy(() => import('./pages/InternsPage')));
const BenefitsPage = Loadable(lazy(() => import('./pages/BenefitsPage')));
const ContactPage = Loadable(lazy(() => import('./pages/ContactPage')));
const AboutPage = Loadable(lazy(() => import('./pages/AboutPage')));
const ProcessPage = Loadable(lazy(() => import('./pages/ProcessPage')));


export const routes = [
  { path: '/', element: <LandingPage /> },
  { path: '/methods', element: <MethodsPage /> },
  { path: '/in-class', element: <InClassPage /> },
  { path: '/internships', element: <InternsPage /> },
  { path: '/benefits', element: <BenefitsPage /> },
  { path: '/process', element: <ProcessPage /> },
  { path: '/about', element: <AboutPage /> },
  { path: '/contact', element: <ContactPage /> },
];

