import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { LogoFull, LogoFullCutout, LogoFullStacked } from '../../assets/logos'
import { UpCaretSVG } from '../../assets/icons'
import { hashScroll } from '../../lib/Functions'
import { images } from '../../lib/Images'

const Landing = () => {
  return (
    <Grid
      container
    >
      <SectionTemplate
        header="Get Real Experience From The Real World."
        body={
          <>A <span style={{fontStyle:'italic', fontWeight:'600'}}>free</span> service for students in college and university.</>
        }
        color="#321266"
        img={images.landing}
        bottomAction={
          <Button
            onClick={() => hashScroll('what-we-do') }
            color="white"
            fullWidth
            sx={{
              position:'absolute',
              bottom:0,
              left:0,
              pb:4,
              fontSize:'1.5rem',
              display:'flex',
              flexDirection:'column'
            }}
          >
            See What We Do
            <UpCaretSVG width={40} color1="#fff" style={{transform:'rotate(180deg)'}}/>
          </Button>
        }
      />

      <SectionTemplate
        id="what-we-do"
        header="Students add real work to their resumes."
        body={
          <><span style={{fontFamily:'Montserrat', fontWeight:500}}>Real</span><span style={{fontFamily:'Montserrat', fontWeight:500, color:'#ffffffbb'}}>Experience</span> is not yet publicly available. Beta tests are in process with select colleges and universities by invitation only.</>
        }
        color="#122866"
        img={images.whatWeDo}
        bottomAction={
          <Button
            onClick={() => hashScroll('how-we-do') }
            color="white"
            fullWidth
            sx={{
              position:'absolute',
              bottom:0,
              left:0,
              pb:4,
              fontSize:'1.5rem',
              display:'flex',
              flexDirection:'column'
            }}
          >
            See How We Do It
            <UpCaretSVG width={40} color1="#fff" style={{transform:'rotate(180deg)'}}/>
          </Button>
        }
      />

      <SectionTemplate
        id="how-we-do"
        header="In-Class or Individual."
        body="You can collaborate with a company to do an in-class assignment where the work is done as part of your course or students can do an internship outside the classroom."
        color="#321266"
        img={images.howWeDo}
        bottomAction={
          <Grid
            container
            sx={{
              // position:'absolute',
              // bottom:0,
              // left:0,
              mt:3,
              pb:4,
              justifyContent:'space-between'
            }}

          >
            <Button
              href="in-class"
              variant="outlined"
              color="white"
              sx={{
                fontSize:'1.5rem',
                px:'1.5rem'
              }}
            >
              In-Class
            </Button>
            <Button
              href="internships"
              variant="outlined"
              color="white"
              sx={{
                fontSize:'1.5rem',
                px:'1.5rem'
              }}
            >
              Internships
            </Button>
          </Grid>
        }
      />

    </Grid>
  )
}

export default Landing

const SectionTemplate = (props) => {
  const { body, bottomAction, color, header, id, img } = props;

  return (
    <Grid
      id={id}
      container
      sx={{
        px:2,
        position:'relative',
        minHeight:'100vh',
        background:`linear-gradient(45deg, ${color}dd, ${color}dd), url(${img})`,
        backgroundSize:'cover',
        backgroundPosition:'center',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
      >
        <Typography
          variant="h1"
          color="#fff"
          mb={2}
        >
          {header}
        </Typography>
        <Typography
          variant="h4"
          color="#fff"
        >
          {body}
        </Typography>
        {
          bottomAction
        }
      </Grid>
    </Grid>
  )
}