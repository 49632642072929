import React from 'react'

const LoadingScreen = () => {
  return (
    <div>
      
    </div>
  )
}

export default LoadingScreen