import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette:{
    primary:{
      light:'#0775D8',
      main:'#0547DB',
      dark:'#2007D8'
    },
    secondary:{
      light:'#A409D6',
      main:'#7707D8',
      dark:'#450BD3'
    },
    popper:{
      main:'#07D8BF',
      dark:'#07D8BF'
    },
    white:{
      main:'#ffffff',
      dark:'#ffffff'
    },
    background:{
      primary:'#122866',
      secondary:'#321266'
    }
  },
  typography:{
    h1:{
      lineHeight:1.1
    }
  }
});